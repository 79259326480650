.p-zoom-in {
  transform: scale(1.5); 
  transition: transform 0.3s ease; 
}

.progress-color {
  color: #8c6fff;
}

.br-contact form.form-light .form-group input,
.br-contact form.form-light .form-group textarea {
  border-color: #dfdfdf;
  color: #a5a3b3;
  background-color: #fff;
}
.object-fit{
  object-fit: cover;
  width: 100%;
  width: 100%;
}
